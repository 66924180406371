@import url("https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap");

:root {
  --dark: #272838;
  --main: #7E7F9A;
  --main600: #72747E;
  --main500: #6A6C78;
  --main400: #7E7F9A;
  --main300: #9495AD;
  --main200: #A9AAC0;
  --main100: #BEBFCF;
  --main50: #D3D4DF;

  --accentMain: #F3DE8A;
  --accentSecondary: #EB9486;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  background: var(--dark)!important;
}

form {
  background: #ffffff;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
}

h4 {
  text-shadow: 0 1px 1px var(--accentSecondary);
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  text-transform: uppercase;
}

.logo p {
  font-size: 21px;
  font-weight: 900;
  color: white;
  letter-spacing: 1.3px;
}

.gradient-text {
  background: -webkit-linear-gradient(90deg, var(--dark), var(--main400));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MuiContainer-root {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.css-h4y409-MuiList-root {
  height: 100%;
  background: var(--main);
  padding: 1rem!important;
}

.modal p, .modal h2, .modal h3, .modal h4, .modal h5, .modal h6 {
  color: var(--main)!important;
}

.MuiInputBase-input {
  color: #000000b5!important;
}

.css-1gdepfy-MuiInputBase-root-MuiOutlinedInput-root:hover {
  border: .1px solid var(--main)!important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--dark)!important;
}

.css-wf16b5 {
  background: var(--main50)!important;
}

.disclaimer {
  background: var(--main500);
  color: white;
}

.description {
  background-color: var(--main50)!important;
  box-shadow: none!important;
}

.description p {
  margin-bottom: .4rem;
  font-size: 1.1rem!important;
}

.description h4 {
  margin-bottom: 1rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .MuiContainer-root {
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
  .css-rmcv22 {
    font-size: 1.5rem!important;
  }
  .css-1666g5d {
    font-size: 1.2rem!important;
  }
}



/* cookie modal */
.cookie-block {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 8xl;
  border-radius: 0.5rem;
  background-color: var(--main400);
  opacity: 0.9;
  color: #fff;
  margin: 0.625rem;
  margin-bottom: 0.25rem;
  padding: 1.25rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 10;
}

.cookie-text {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.cookie-accept {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--accentMain);
  color: var(--white);
  border-radius: 0.25rem;
  border: 0;
  padding: 0.5rem 1rem;
  margin-left: 0.625rem;
  font-size: 1em;
  cursor: pointer;
}

.cookie-close-wrap {
  border-radius: 0.625rem;
  position: absolute;
  bottom: -5%;
  right: 1%;
  transform: translateY(-50%);
}

.cookie-close-btn {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.25rem 1rem;
  margin-left: 0.625rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--main900)
}

.cookie-close-btn:hover {
  transform: scale(1.25);
}

.cookie-more {
  color: #fff;
  margin-left: 0.125rem;
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  font-size: 0.9em;
}

/* cookie modal */

.no-money-disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  color: white; /* White text color */
  padding: 1rem;
  display: flex;
  align-items: center;
}

.nomoney-icon img { 
  width: 40px;
  opacity: .7;
  filter: saturate(5)
}

.message {
  font-size: 14px;
  font-weight: 900;
  margin-left: 1rem;
}